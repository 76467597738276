import en_EN from '@temperworks/temper-locale-library/src/locales/en-EN.json'
import fr_FR from '@temperworks/temper-locale-library/src/locales/fr-FR.json'
import nl_NL from '@temperworks/temper-locale-library/src/locales/nl-NL.json'

export default defineI18nConfig(() => {
  return {
    legacy: false,
    globalInjection: true,
    locale: 'en_EN',
    lazy: false,
    strategy: 'no_prefix',
    messages: {
      en_EN,
      fr_FR,
      nl_NL,
    }
  }
})
